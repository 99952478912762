import Vue from 'vue';

// Axios
import './plugins/axios';

// Vuetify

// Apex Charts
import '@/plugins/apexcharts';

// Amplify
import '@aws-amplify/ui-vue';
import { Auth, Amplify } from 'aws-amplify';

// Vuex
import Vuex from 'vuex';

// Hotjar
import Hotjar from 'vue-hotjar';

// Sentry
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

// Google Analytics
import VueGtag from 'vue-gtag';

// NumFormat Filter
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

// Moment
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';

// Vue Meta
import VueMeta from 'vue-meta';

// Clipboard
import VueClipboard from 'vue-clipboard2';

// Vue Offline
import VueOffline from 'vue-offline';

// Vue Cookie
import VueCookie from 'vue-cookie';

// JsonViewer
import JsonViewer from 'vue-json-viewer';
import store from './store/store';
import vuetify from './plugins/vuetify';
import router from './router';

import App from './App.vue';

Vue.use(VueOffline);

Vue.use(VueCookie);

Vue.use(Vuex);

Vue.use(Hotjar, {
  id: '2263446',
  isProduction: process.env.NODE_ENV === 'production',
});

Sentry.init({
  Vue,
  dsn: 'https://69a324d16db940708066a9971a794576@o565816.ingest.sentry.io/5707780',
  integrations: [new Integrations.BrowserTracing()],
  release: 'apphub@' + process.env.PACKAGE_VERSION, // eslint-disable-line
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.use(VueGtag, {
  config: { id: 'G-D0WMKW971V' },
});

Vue.filter('numFormat', numFormat(numeral));

Vue.use(VueMoment, {
  moment,
});

// Fixes moment console warnings: https://github.com/brockpetrie/vue-moment/issues/75
Vue.filter('moment', function (value, format) { // eslint-disable-line
  if (value === null || value === undefined || format === undefined) {
    return '';
  }
  if (format === 'from') {
    return moment(value).fromNow();
  }
  return moment(value).format(format);
});

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

Vue.use(VueClipboard);

Vue.use(JsonViewer);

// Amplify.configure(awsconfig);
Amplify.configure({
  Auth: {
    region: 'us-east-1', // process.env.VUE_APP_AWS_REGION,
    userPoolId: 'us-east-1_rGUPH52gS', // 'us-east-1_rGUPH52gS' 'us-east-1_fqHhX2ii6', // process.env.VUE_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: '6eut0s9f6dq9iks8ccaid8rr39', // '4b27v34ss5365s3o1l57bia872', // '3g4skn36f7ja43b9g2l093ipb8',
    // '6eut0s9f6dq9iks8ccaid8rr39', // process.env.VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    // oauth: {
    //   domain: 'westbend-test.terrenelabs.com', // The Auth0 Domain,
    // apphub-test.underwriting.ai', // sso.underwriting.ai
    //   scope: ['email', 'phone', 'profile', 'openid'],
    // ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
    //   redirectSignIn: 'http://localhost:8080/login',
    // `https://${config.domain || location.host}${(location.port)? `:${location.port}` : ''}/Login`,
    //   redirectSignOut: 'http://localhost:8080/logout',
    // `https://${config.domain || location.host}${(location.port)? `:${location.port}` : ''}/Login`,
    //   responseType: 'token',
    // },
    cookieStorage: {
      domain: process.env.NODE_ENV === 'production' ? 'underwriting.ai' : 'localhost',
      secure: false,
      path: '/',
      expires: 365,
    },
  },
  API: {
    endpoints: [
      {
        name: 'AppHubAPI',
        endpoint: 'https://apphub-api.underwriting.ai/', // process.env.VUE_APP_API_ENDPOINT,
        // https://apphub-api-test.underwriting.ai/
        // service: 'lambda',
        // region: 'us-east-1',
        custom_header: async () => { // eslint-disable-line
        // return { Authorization : 'token' }
        // Alternatively, with Cognito User Pools use this:
        // return { Authorization: `Bearer ${(await Auth.cur
        // rentSession()).getAccessToken().getJwtToken()}` };
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        },
      },
    ],
  },
});

Vue.config.productionTip = false;

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAdmin)) {
//     // this route requires admin, check if admin
//     // if not, redirect to client activity page
//     if (store.state.isAdmin === false) {
//       next({
//         path: `/activity/${store.state.client}`,
//       });
//     } else {
//       next();
//     }
//   } else {
//     next(); // make sure to always call next()!
//   }
// });

/* eslint-disable no-unused-vars */
// router.beforeResolve((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     let user;
//     Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
//       .then((data) => {
//         if (data && data.signInUserSession) {
//           user = data;
//         }
//         next();
//       })
//       .catch((e) => {
//         next({
//           path: '/login',
//         });
//       });
//   }
//   next();
// });
/* eslint-enable no-unused-vars */

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
