import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// import { Auth } from 'aws-amplify';
// import getUserProfile from '@/services/getUserProfile';

// async function getJWT() {
//   const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();

//   return jwt;
// }

// console.log(getJWT());

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    user: [], // Cognito user
    portalUser: [], // eslint-disable-line
    // newKratosUser: [], // eslint-disable-line
    packageVersion: process.env.PACKAGE_VERSION || '0',
    buildVersion: process.env.BUILD_VERSION || '0',
    portalAccess: false,
    reportAccess: false,
    apiAccess: false,
    activityAccess: false,
    bookSnapshotAccess: false,
    isAdmin: false,
    isAuth: false,
    firstName: '',
    lastName: '',
    userEmail: '',
    client: '',
    accountId: 'acct-test', // acct-3222af9b-3c73-4ed8-a4c4-9ff656d885cf
    SAMLRequest: '',
    SAMLResponse: '',
    RelayState: '',
  },
  getters: {
    user: (state) => (
      state.user
    ),
    appVersion: (state) => (
      state.packageVersion
    ),
    buildVersion: (state) => (
      state.buildVersion
    ),
    portalAccess: (state) => (
      state.portalAccess
    ),
    reportAccess: (state) => (
      state.reportAccess
    ),
    apiAccess: (state) => (
      state.apiAccess
    ),
    activityAccess: (state) => (
      state.activityAccess
    ),
    bookSnapshotAccess: (state) => (
      state.bookSnapshotAccess
    ),
    isAdmin: (state) => (
      state.isAdmin
    ),
    isAuth: (state) => (
      state.isAuth
    ),
    firstName: (state) => (
      state.firstName
    ),
    lastName: (state) => (
      state.lastName
    ),
    userEmail: (state) => (
      state.userEmail
    ),
    // portalUser: (state) => (
    //   state.portalUser
    // ),
    client: (state) => (
      state.client
    ),
    accountId: (state) => (
      state.accountId
    ),
    SAMLRequest: (state) => (
      state.SAMLRequest
    ),
    SAMLResponse: (state) => (
      state.SAMLResponse
    ),
    RelayState: (state) => (
      state.RelayState
    ),
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    portalUser(state, user) {
      state.portalUser = user;
    },
    // newKratosUser(state, user) {
    //   state.newKratosUser = user;
    // },
    isAdmin(state, permission) {
      state.isAdmin = permission;
    },
    isAuth(state, authRole) {
      state.isAuth = authRole;
    },
    firstName(state, firstNameRole) {
      state.firstName = firstNameRole;
    },
    lastName(state, lastNameRole) {
      state.lastName = lastNameRole;
    },
    userEmail(state, userEmailRole) {
      state.userEmail = userEmailRole;
    },
    portalAccess(state, portalRole) {
      state.portalAccess = portalRole;
    },
    reportAccess(state, reportRole) {
      state.reportAccess = reportRole;
    },
    apiAccess(state, apiRole) {
      state.apiAccess = apiRole;
    },
    activityAccess(state, activityRole) {
      state.activityAccess = activityRole;
    },
    bookSnapshotAccess(state, bookSnapshotRole) {
      state.bookSnapshotAccess = bookSnapshotRole;
    },
    client(state, client) {
      state.client = client;
    },
    accountId(state, accountId) {
      state.accountId = accountId;
    },
    SAMLRequest(state, SAMLRequest) {
      state.SAMLRequest = SAMLRequest;
    },
    SAMLResponse(state, SAMLResponse) {
      state.SAMLResponse = SAMLResponse;
    },
    RelayState(state, RelayState) {
      state.RelayState = RelayState;
    },
  },
  actions: {
    client(ctx, client) {
      ctx.commit('client', client);
    },
    accountId(atx, accountId) {
      atx.commit('accountId', accountId);
    },
  },
  plugins: [createPersistedState()],
});
