<template>
  <div>
    <v-list-item
      @click.stop="dialog = true"
    >
      <v-list-item-icon>
        <v-icon>mdi-information-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>About AppHub</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          About AppHub
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-img
                  :src="require('@/assets/apphub_logo.png')"
                  alt="AppHub Logo"
                  width="120"
                ></v-img>
              </v-col>
              <v-col>
                <p>AppHub is the central location to access all
                  your FRISS Underwriting Insight products.</p>
              </v-col>
            </v-row>
          </v-container>

          <!-- <div v-if="userAdmin">
            <h2 class="title">Permissions</h2>
            <v-chip-group
              column
            >
              <v-chip
                small
                outlined
                color="primary"
                v-if="userAdmin"
              >Admin</v-chip>
              <v-chip
                small
                outlined
                v-for="tag in userPermissions"
                :key="tag"
              >
                {{ tag }}
              </v-chip>
            </v-chip-group>
          </div> -->

          <small>
            Logged in as: <strong>{{ userEmail }}</strong>
            <v-btn
              small
              outlined
              class="ml-2"
              @click="signOut"
            >Sign Out</v-btn>
          </small>
          <br>
          <small>AppHub v{{ appVersion }} ({{ appBuild }})</small>
          <br>
          <small>Copyright ©{{ new Date().getFullYear() }} Terrene, Inc.</small>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'AboutAppHub',
  props: {
    user: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    // userPermissions() {
    //   return this.$store.state.portalUser.userDetails.permissions || null;
    // },
    userEmail() {
      return this.$store.state.userEmail || 'unknown';
    },
    userAdmin() {
      return this.$store.state.isAdmin || null;
    },
    authUser() {
      // return this.$store.state.user || null;
      return this.$store.state.portalUser || null;
    },
    appVersion() {
      return this.$store.getters.appVersion || null;
    },
    appBuild() {
      return this.$store.getters.buildVersion || null;
    },
  },
  methods: {
    async signOut() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log('error signing out: ', error); // eslint-disable-line
      }
    },
  },
};
</script>
