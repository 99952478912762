import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  // theme: {
  //   options: {
  //     customProperties: true,
  //   },
  //   themes: {
  //     dark: {
  //       background: '#1d262f',
  //     },
  //   },
  // },
});
