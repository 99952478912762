import Vue from 'vue';
import VueRouter from 'vue-router';
import { Auth } from 'aws-amplify';

// Service
import goTo from 'vuetify/es5/services/goto';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (activity[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "logout" */ '../views/auth/Login.vue'),
  },
  {
    path: '/sso',
    name: 'SSO',
    component: () => import(/* webpackChunkName: "logout" */ '../views/auth/SSO.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Logout.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgot" */ '../views/auth/ForgotPassword.vue'),
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "signup" */ '../views/auth/SignUp.vue'),
  },
  {
    path: '/auth/openIdLogin',
    name: 'OIDLogin',
    component: () => import(/* webpackChunkName: "oidlogin" */ '../views/auth/OIDLogin.vue'),
  },
  {
    path: '/api',
    name: 'API',
    component: () => import(/* webpackChunkName: "api" */ '../views/API.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reports/call-detail-report',
    name: 'Call Detail Report',
    component: () => import(/* webpackChunkName: "calldetail" */ '../components/reports/CallDetail.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reports/user-feedback-report',
    name: 'User Feedback Report',
    component: () => import(/* webpackChunkName: "userfeedback" */ '../components/reports/UserFeedback.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reports/loss-run-report',
    name: 'Loss Run Report',
    component: () => import(/* webpackChunkName: "lossrun" */ '../components/reports/LossRun.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/activity',
    name: 'Activity Overview',
    component: () => import(/* webpackChunkName: "activityoverview" */ '../views/ActivityOverview.vue'),
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
    },
  },
  {
    path: '/activity/:id',
    name: 'Activity',
    component: () => import(/* webpackChunkName: "activity" */ '../views/Activity.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser().then(() => {
      next();
    }).catch(() => {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    });
  }
  next();
});

// router.beforeEach(function(to, from, next) {
//   // prevent access to login & register after signing in
//   if (to.matched.some(record => record.meta.requiresGuest) && auth.user.authenticated) {
//     next({
//       path: '/dashboard'
//     });
//   } else if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!auth.user.authenticated) {
//       next({
//         path: '/auth/signin',
//         query: {
//           redirect: to.fullPath
//         }
//       })
//     }
//   } else {
//     next() // make sure to always call next()!
//   }
// })

export default router;
