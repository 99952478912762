<template>
  <v-app>
    <!-- v-if="authState === 'signedin' && user" -->
    <v-app-bar
      app
      elevate-on-scroll
      color="white"
    >
      <router-link to="/">
        <v-img
          alt="FRISS Underwriting Insights Logo"
          class="mr-2"
          contain
          src="@/assets/friss_logo.png"
          width="300"
        />
      </router-link>

      <v-spacer></v-spacer>

      <!-- v-if="authState === 'signedin' && user" -->
      <!-- v-if="this.$store.state.user" -->
      <!-- v-if="!!signedIn" -->
      <v-menu
        v-if="authUser"
        bottom
        min-width="200px"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
            class="menu-btn"
          >
            <v-avatar
              size="32"
              class="avatar"
            >
              {{ givenName.charAt(0) }}
            </v-avatar>
            <span class="d-none d-sm-flex">
              {{ givenName }}
              {{ familyName }}
            </span>
            <v-icon class="d-none d-sm-flex">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <HelpAppHub />
          <AboutAppHub />

          <v-divider></v-divider>

          <v-list-item @click="signOut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <!-- <div v-if="authState === 'signedin' && user"> -->
        <router-view :userName="firstName"></router-view>
      <!-- </div> -->

      <v-snackbar v-model="isOffline">
        <v-icon>mdi-alert-circle-outline</v-icon>
        Network connectivity issue.

        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="isOffline = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { Auth } from 'aws-amplify';
import AboutAppHub from '@/components/AboutAppHub.vue';
import HelpAppHub from '@/components/HelpAppHub.vue';
// import getUserProfile from '@/services/getUserProfile';
// import getRefreshToken from '@/services/getRefreshToken';

export default {
  name: 'App',
  metaInfo: {
    title: 'AppHub',
    titleTemplate: '%s | FRISS Underwriting Insights',
  },
  computed: {
    networkStatus() {
      return this.isOnline ? 'Network is online' : 'I am offline';
    },
    givenName() {
      // return this.$store.state.user.attributes.given_name || null;
      return this.$store.state.firstName || 'friend';
    },
    familyName() {
      // return this.$store.state.user.attributes.family_name || null;
      return this.$store.state.lastName || null;
    },
    authUser() {
      // return this.$store.state.user || null;
      return this.$store.state.isAuth || null;
    },
    userEmail() {
      // return this.$store.state.user.attributes.email || null;
      return this.$store.state.userEmail || null;
    },
  },
  // created() {
  //   this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
  //     this.authState = authState;
  //     this.user = authData;
  //     this.getRefreshToken();
  //   });
  // },
  data() {
    return {
      user: undefined,
      authState: undefined,
      firstName: 'friend',
      unsubscribeAuth: undefined,
      isOffline: false,
      loading: true,
      error: false,
      message: '',
    };
  },
  // beforeDestroy() {
  //   this.unsubscribeAuth();
  // },
  methods: {
    clearSessionKeys() {
      if (window && 'localStorage' in window) {
        Object
          .keys(localStorage).filter((key) => key.search(/CognitoIdentityServiceProvider/) >= 0)
          .forEach((key) => {
            localStorage.removeItem(key);
          });
      }
    },
    async signOut() {
      try {
        // clear session keys
        this.clearSessionKeys();

        // Clear the session
        sessionStorage.clear();

        // Signout
        await Auth.signOut();

        // Clear the user
        this.$store.commit('user', null);
        this.$store.commit('client', null);
        this.$store.commit('portalUser', null);
        this.$store.commit('isAdmin', null);
        this.$store.commit('isAuth', null);
        this.$store.commit('portalAccess', null);
        this.$store.commit('reportAccess', null);
        this.$store.commit('apiAccess', null);
        this.$store.commit('activityAccess', null);
        this.$store.commit('bookSnapshotAccess', null);

        // Go to login
        this.$router.push('/login');
      } catch (error) {
        console.log('error signing out: ', error); // eslint-disable-line
      }
    },
  },
  created() {
    this.firstName = this.givenName;
  },
  watch: {
    firstName() {
      this.firstName = this.givenName;
    },
  },
  components: {
    HelpAppHub,
    AboutAppHub,
  },
};
</script>

<style>
.avatar {
  border: 1px solid #333;
  margin-right: 0.25rem;
  text-transform: capitalize;
}

.menu-btn {
  text-transform: capitalize;
}

.username {
  text-transform: capitalize;
}
</style>
