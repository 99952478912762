<template>
  <div>
    <v-list-item
      @click.stop="dialog = true"
    >
      <v-list-item-icon>
        <v-icon>mdi-book-open-page-variant-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>AppHub Guide</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          AppHub Guide
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <h2 class="font-weight-light subtitle">Sign In</h2>
                <p>When you first arrive at AppHub you will be
                  asked to sign in to your user account.</p>
                <small class="caption">(Note: This login is the same login
                  as your underwriting portal login)</small>

                <h2 class="font-weight-light subtitle">Welcome & Overview</h2>
                <p>Once signed-in you will be greeted along
                  with the applications you have access to.</p>

                <h2 class="font-weight-light subtitle">Applications</h2>
                <p>Access will vary per account, but will
                  include one or more of the following:</p>

                <h2 class="font-weight-light subtitle">Portal</h2>
                <p>Portal gives you quick access to your underwriting portal,
                  where you can search for business to gather information for a given risk.</p>

                <h2 class="font-weight-light subtitle">Reports</h2>
                <p>Reports give you instant access to call
                  detail reporting and user feedback reports.</p>

                <h2 class="font-weight-light subtitle">API</h2>
                <p>API enables your developers to get
                  started with our APIs including authorization,
                  test & production endpoints, swagger specs and more.</p>

                <h2 class="font-weight-light subtitle">Activity</h2>
                <p>Activity enables users to self-service and monitor
                  their API usage in an intuitive analytic dashboard.</p>

                <h2 class="font-weight-light subtitle">Booksnapshot</h2>
                <p>Booksnapshot gives you access to deep
                  insight and analysis data into your risks.</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'HelpAppHub',
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
